import React from 'react'
import Layout from '~/layouts/layout'
import SEO from '~/components/seo'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const verhaal = ({ data }) => {
  // console.log(data.allInstagramContent.edges)
  const instagramPhotos = data.allInstagramContent.edges
  return (
    <Layout language="en" en="our-story" nl="ons-verhaal">
      <SEO
        title="Our Story"
        keywords={[`valta`, `lamp`, `licht`, `about us`]}
      />
      <div className="articleHero gridFullWidth">
        <h1>Our Story</h1>
      </div>
      <article className="gridFullWidth">
        <div className="articleContent">
          <h2>Valta Studio is a Dutch design studio based in Groningen.</h2>
          <p>
            Our philosophy is simple: We design good-looking and functional
            products, with as many locally sourced and sustainable materials as
            possible.
          </p>
          <p>
            To do this, we use modern technologies like 3D printing as well as
            traditional woodworking methods.
          </p>
          <p>
            Thanks to these techniques, we are able to turn ideas into real-life
            products quickly and improve them rapidly, and produce our products
            locally.
          </p>
          <p>
            Follow all our new developments and new designs on{' '}
            <a href="https://instagram.com/valtastudio">Instagram</a>.
          </p>
        </div>
      </article>
      <div className="articleHero gridFullWidth">
        <h1>Follow us on Instagram</h1>
      </div>
      {instagramPhotos.map(instagramPhoto => {
        const photo = instagramPhoto.node
        return (
          <>
            {photo.localImage.childImageSharp && (
              <a
                className="grid4 instaPhoto"
                href="https://instagram.com/valtastudio"
              >
                <Img
                  fluid={photo.localImage.childImageSharp.fluid}
                  // key={singleProduct.node.images[0].id}
                  // alt={singleProduct.node.title}
                />
              </a>
            )}
          </>
        )
      })}
    </Layout>
  )
}

export const query = graphql`
  query Instagram {
    allInstagramContent {
      edges {
        node {
          caption
          media_url
          localImage {
            childImageSharp {
              fluid(maxHeight: 500, maxWidth: 500, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

export default verhaal
